import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./templateManager.actions";
import { getters } from "./templateManager.getters";
import { mutations } from "./templateManager.mutations";
import { state } from "./templateManager.state";
import { TemplateModelState } from "./templateManager.types";

const namespaced: boolean = true;

export const TemplateModelModule: Module<TemplateModelState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
