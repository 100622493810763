import { TemplateEditor } from "@/components/templateEditor/editors.declaration";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";
import { TemplateModelState } from "./templateManager.types";

type TemplateModelGetter = GetterTree<TemplateModelState, RootState>;

export const getters: TemplateModelGetter = {
  templates(state: TemplateModelState): TemplateEditor.TemplateModel[] | null {
    return state.templates;
  },
  pagination(state: TemplateModelState): TablePagination | null {
    return state.pagination;
  },
  loading(state: TemplateModelState): boolean {
    return state.loading;
  }
};
