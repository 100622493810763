import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { TemplateModelState } from "./templateManager.types";

type TemplateMutationTree = MutationTree<TemplateModelState>;

export const mutations: TemplateMutationTree = {
  setTemplates(
    state: TemplateModelState,
    templates: TemplateEditor.TemplateModel[] | null
  ) {
    state.templates = templates;
  },
  setLoading(state: TemplateModelState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: TemplateModelState, pagination: TablePagination | null) {
    state.pagination = pagination;
  }
};
