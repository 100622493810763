import { TemplateModelModule } from "@/vuex/modules/templateManager/templateManager.index";
import { Component, Vue } from "vue-property-decorator";

@Component({
  render: c => c("router-view")
})
export default class TemplateManagerParentComponent extends Vue {
  protected created() {
    this.$store.registerModule("TemplateModelModule", TemplateModelModule);
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("TemplateModelModule");
  }
}
